import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

/**
 * Debug
 */
const gui = new GUI()
gui.hide()

window.addEventListener('keydown', (event) => {
    if (event.key == 'h')
        gui.show(gui._hidden)
})

let tapCount = 0
let tapTimeout

window.addEventListener('touchstart', (event) => {
    if (event.touches.length === 1) {
        tapCount++

        if (tapCount === 1) {
            tapTimeout = setTimeout(() => {
                tapCount = 0
            }, 300) // 300ms interval between taps
        } else if (tapCount === 3) {
            gui.show(gui._hidden)

            tapCount = 0
            clearTimeout(tapTimeout)
        }
    }
})

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const loadingManager = new THREE.LoadingManager()
// const progressBarFill = document.getElementById('progress-bar-fill')

// Update progress bar
// loadingManager.onProgress = function (item, loaded, total) {
//     const progress = (loaded / total) * 100
//     progressBarFill.style.width = `${progress}%`
// }

// Hide loading screen and start animation when done
loadingManager.onLoad = function () {
    document.getElementById('loading-screen').classList.add('hidden')
    startAnimation()
}

const fontLoader = new FontLoader(loadingManager)

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        // Material
        const material = new THREE.MeshPhysicalMaterial()
        material.metalness = 0.9
        material.roughness = 0
        material.transmission = 10

        // Subtitle Material
        const subMaterial = new THREE.MeshPhysicalMaterial()
        subMaterial.metalness = 0.9
        subMaterial.roughness = 0
        subMaterial.transmission = 10
        subMaterial.color.set('#9c71e5')
        gui.add(subMaterial, 'metalness').min(0).max(1).step(0.0001)
        gui.add(subMaterial, 'roughness').min(0).max(1).step(0.0001)
        gui.add(subMaterial, 'transmission').min(0).max(10).step(0.0001)
        gui.addColor(subMaterial, 'color').name('Color')

        // Text
        const textGeometry = new TextGeometry(
            'kusiak.dev',
            {
                font: font,
                size: 0.8,
                depth: 0.3,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.04,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        const subGeometry = new TextGeometry(
            'Coming soon',
            {
                font: font,
                size: 0.8,
                depth: 0.3,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.04,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        textGeometry.center()
        subGeometry.center()
        const text = new THREE.Mesh(textGeometry, material)
        const subText = new THREE.Mesh(subGeometry, subMaterial)
        scene.add(text)
        scene.add(subText)

        subText.position.y = 4
        subText.position.z = -5
        subText.position.x = -1
    }
)

/**
 * Environment map
 */
const rgbeLoader = new RGBELoader(loadingManager)

// Load the 2K environment map first
rgbeLoader.load('./textures/environmentMap/2kmap.hdr', (environmentMap2k) => {
    environmentMap2k.mapping = THREE.EquirectangularReflectionMapping

    scene.background = environmentMap2k
    scene.environment = environmentMap2k
})


const rgbeLoader4k = new RGBELoader()
// Load the 4K environment map in the background
rgbeLoader4k.load('./textures/environmentMap/4kmap.hdr', (environmentMap4k) => {
    environmentMap4k.mapping = THREE.EquirectangularReflectionMapping

    // Replace the 2K environment map with the 4K environment map
    scene.background = environmentMap4k
    scene.environment = environmentMap4k
})


// Base material
const baseMaterial = new THREE.MeshPhysicalMaterial()
baseMaterial.metalness = 0.45
baseMaterial.roughness = 0
baseMaterial.transmission = 1
baseMaterial.wireframe = true
baseMaterial.color.set('lightgreen')

gui.add(baseMaterial, 'metalness').min(0).max(1).step(0.0001)
gui.add(baseMaterial, 'roughness').min(0).max(1).step(0.0001)

// Transmission
baseMaterial.transmission = 1
baseMaterial.ior = 1.5
baseMaterial.thickness = 0.5

gui.add(baseMaterial, 'transmission').min(0).max(10).step(0.0001)
gui.add(baseMaterial, 'ior').min(1).max(10).step(0.0001)
gui.add(baseMaterial, 'thickness').min(0).max(1).step(0.0001)

// Objects
const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(0.5, 32, 16),
    baseMaterial
)
sphere.position.x = -1.5
sphere.position.y = -1.2

const plane = new THREE.Mesh(
    new THREE.BoxGeometry(0.7, 0.7, 0.7, 15, 15, 15),
    baseMaterial
)
plane.position.y = -1.2

const torus = new THREE.Mesh(
    new THREE.TorusGeometry(0.3, 0.2, 12, 48),
    baseMaterial
)
torus.position.x = 1.5
torus.position.y = -1.2

scene.add(sphere, plane, torus)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const duration = 3

const startAnimation = () => {
    const tick = () => {
        const elapsedTime = clock.getElapsedTime()

        // Calculate damping factor
        const dampingFactor = Math.exp(-elapsedTime / duration)

        // Update objects
        sphere.rotation.y = -0.1 * elapsedTime
        plane.rotation.y = 0.1 * elapsedTime
        torus.rotation.y = -0.1 * elapsedTime

        sphere.rotation.x = 0.15 * elapsedTime
        plane.rotation.x = -0.15 * elapsedTime
        torus.rotation.x = -0.15 * elapsedTime

        if (elapsedTime <= duration) {
            camera.position.y = 0 * elapsedTime * dampingFactor
            camera.position.z = 7 * elapsedTime * dampingFactor
            camera.position.x = 0
        }

        // Update controls
        controls.update()

        // Render
        renderer.render(scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()
}

// Start animation on window load
window.addEventListener('load', () => {
    // Loading animation will start after the loading screen is hidden
})
